import React, { useState } from "react";
import PopupEdit from "../../../component/PopupEdit/PopupEdit";
import InputsCalendarioPlantio from "./InputsCalendarioPlantio";

const PopupEditCalendario = ({ item, baseUrl, setLoadData }) => {
    const [id, setId] = useState("");
    const [regiao, setRegiao] = useState("");
    const [dataInicial, setDataInicial] = useState("");
    const [dataFinal, setDataFinal] = useState("");
    const [tipo, setTipo] = useState("");
    const [produtoId, setProdutoId] = useState("");
    const [ano, setAno] = useState("");

    const [errorMessage, setErrorMessage] = useState("");
    const [errorColumn, setErrorColumn] = useState("");

    const [blockSubmit, setBlockSubmit] = useState(false);

    const tipoList = [
        { value: 'PLANTIO', label: 'Plantio' },
        { value: 'COLHEITA', label: 'Colheita' },
    ]

    const inputs = (
        <InputsCalendarioPlantio
            regiaoId={regiao} setRegiaoId={setRegiao}
            dataInicial={dataInicial} setDataInicial={setDataInicial}
            dataFinal={dataFinal} setDataFinal={setDataFinal}
            tipo={tipo} setTipo={setTipo}
            produtoId={produtoId} setProdutoId={setProdutoId}
            ano={ano} setAno={setAno}
            setErrorColumn={setErrorColumn}
            setErrorMessage={setErrorMessage}
            errorMessage={errorMessage}
            errorColumn={errorColumn}
            tipoList={tipoList}
        />
    );

    const itemToState = () => {
        setId(item.id); // Assuming item has an 'id' property
        setRegiao(item.regiao);
        setDataInicial(item.dataInicial)
        setDataFinal(item.dataFinal)
        setTipo(tipoList.find(t => t.value === item.tipo) || null);
        setProdutoId(item.produtoId)
        setAno(item.ano)
        setErrorMessage("");
        setErrorColumn("");
        setBlockSubmit(false);
    };

    const title = "Editar Calendário";

    const returnData = () => {
        console.log({
            regiao,
            dataInicial,
            dataFinal,
            tipo,
            produtoId,
            ano
        });
        return {
            regiao,
            dataInicial,
            dataFinal,
            tipo: tipo.value,
            produtoId,
            ano
        };
    };

    return (
        <>
            <PopupEdit
                baseUrl={baseUrl + '/' + id}
                setLoadData={setLoadData}
                inputs={inputs}
                title={title}
                returnData={returnData}
                itemToState={itemToState}
                setErrorMessage={setErrorMessage}
                setErrorColumn={setErrorColumn}
            />
        </>
    );
};
export default PopupEditCalendario;
