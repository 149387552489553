import React, {useState} from "react";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import SelectInput from "../../../component/SelectInput";
import Select from "react-select";
import TextField from "@mui/material/TextField";
import {ApiUrl} from "../../../auth/authMethods";
import Divider from "@mui/material/Divider";


export default function InputsCalendarioPlantio({
                                                    ano,setAno,
                                                    tipoList,
                                                    dataInicial,setDataInicial,
                                                    dataFinal,setDataFinal,
                                                    tipo,setTipo,
                                                    produtoId,setProdutoId,
                                                    regiaoId,setRegiaoId,
                                                    errorColumn, errorMessage
                                                }) {

    const [produtoList, setProdutoList] = useState([])
    const [regiaoList, setRegiaoList] = useState([])

    // const regioes = [
    //     'Norte',
    //     'Nordeste',
    //     'Centro-Oeste',
    //     'Sudeste',
    //     'Sul'
    // ]


    const handleChangeData = (value, set) => {
        let data = new Date(value)
        try {
            set(data.toISOString().slice(0, 10))
        } catch (e) {
            set(value)
        }
    }

    return (
        <React.Fragment>
        {/*Colocar inputs de cadastro com onChange*/}
            <SelectInput
                url={`${ApiUrl}/produto?size=2000`}
                list={produtoList} set={setProdutoList}
                setObj={setProdutoId}
                returnList={() => {
                    let returnList = []
                    produtoList.forEach((item) => {
                        returnList.push({value: item.id, label: item.tipo + " - " + item.variedade})
                    })
                    return returnList
                }}
                placeholder={"Produto"}
                defaultValue={produtoId}
                required={true}
                isDisabled={false}
                isLoading={false}
                isClearable={false}
                isRtl={false}
                isSearchable={true}
                fullWidth
            />
            <Divider/>
        <DatePicker
            label="Data Inicial"
            sx={{
                width: '100%'
            }}
            defaultValue={dataInicial ? dayjs(dataInicial) : null}
            onChange={(e) => handleChangeData(e, setDataInicial)}
            slotProps={{
                textField: {
                    required: true
                },
            }}
            autoFocus
        />
        <DatePicker
            label="Data Final"
            sx={{
                width: '100%'
            }}
            defaultValue={dataFinal ? dayjs(dataFinal) : null}
            onChange={(e) => handleChangeData(e, setDataFinal)}
            slotProps={{
                textField: {
                    required: true
                },
            }}
        />
            <Divider/>
            <SelectInput
                url={`${ApiUrl}/calendario/regioes`}
                list={regiaoList} set={setRegiaoList}
                setObj={setRegiaoId}
                returnList={() => {
                    let returnList = []
                    regiaoList.forEach((item) => {
                        returnList.push({value: item.nome, label: item.nome})
                    })
                    return returnList
                }}
                placeholder={"Região"}
                defaultValue={regiaoId}
                required={true}
                fullWidth
            />
            <Select
                options={tipoList}
                onChange={(e) => setTipo(e.value)}
                placeholder={"Tipos"}
                value={tipo}
                isDisabled={false}
                isLoading={false}
                isClearable={false}
                isRtl={false}
                isSearchable={true}
                required={true}
                fullWidth
            />

            <TextField
                label="Ano"
                iinputProps={{
                    maxLength: 4
                }}
                type="number"
                value={ano}
                onChange={(e) =>{
                    if (e.target.value.length <= 4) {
                        setAno(e.target.value);
                    }
                }}
                inputProps={{
                    min: 1970,
                    max: 2100,
                    maxLength: 5
                }}
                InputLabelProps={{
                    style: {
                        zIndex: 0
                    },
                }}
                required
                fullWidth
            />


    </React.Fragment>
    )
}

